import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    InputBox,
    Card,
    Modal,
    notification,
    calculation,
} from "../../components/Elements/appUtils";
import {getToken} from "../../request";
import TableMain from "../../components/Elements/Table";
import {getStockUrl, getbatchRecordByProductIdUrl, getBatchForCreditNote} from "./api";
import _ from "lodash";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import {arraySorter} from "../../components/_utils/appUtils";

let initState = {
    productId: "",
    productName: "",
    quantity: 1,
    price: "",
    salePrice: "",
    inclusivePrice: "",
    pUnit: "",
    hsn: "",
    subTotal: "",
    discount: "",
    discountAmount: "",
    expiryDate: "",
    manufacturingDate: "",
    batchNo: "",
    batchId: "",
    taxableAmount: "",
    igstAmount: "",
    cgstAmount: "",
    sgstAmount: "",
    igstTax: "",
    gst: "",
    uom: "",
    totalAmount: "",
    gstAmount: "",
    netAmount: "",
    stateOfSupply: "",
    customerId: "",
    reportType: "Inclusive",
    transactionType: "",
    BatchQunatity: "",
};

const ItemListComponent = (props) => {
    let {
        ProductReport,
        updateProductReport,
        statesAreEqual,
        stateOfSupply,
        companyState,
        batchNumberList
    } = props;
    console.log(batchNumberList, "batchNumberList")
    const discountCode = getItemLocalstorage("user")["discount"];
    const [stock, setStock] = useState([]);
    let [k, setk] = useState("");
    let [Batch, setBatch] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    let [productState, setProductState] = useState(initState);
    let [productList, setProductList] = useState([]);

    const fetchStock = async () => {
        try {
            const response = await axios.get(getStockUrl(), getToken());
            const sortedArray = arraySorter(response.data.data);
            setStock(sortedArray);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        setProductList(ProductReport);
    }, [ProductReport]);


    const handleBatchSelect = (Batch) => {
        let obj = {
            batchId: Batch._id,
            batchNo: Batch.batchNo,
            price: Batch.salePrice ? Batch.salePrice : 0,
            manufacturingDate: Batch.manufacturingDate,
            expiryDate: Batch.expiryDate,
            BatchQunatity: Batch.quantity,
            salePrice: Batch.salePrice,
        };
        setIsModalOpen(false);
        setProductState((prevState) => {
            return {
                ...prevState,
                ...obj,
            };
        });
    };


    useEffect(() => {
        reCalculateAmount().then(() => {
            setk(new Date().toString());
        });
    }, [
        productState.productId,
        productState.reportType,
        productState.price,
        productState.quantity,
        productState.discount,
        productState.gst,
    ]);

    const reCalculateAmount = () => {
        return new Promise((resolve) => {
            calculation({...productState}, stateOfSupply, companyState).then(
                (resp) => {
                    setProductState({
                        ...productState,
                        ...resp,
                    });
                }
            );
            return resolve("done");
        });
    };

    const StateChangesRecalculate = () => {
        // Assuming productState is an array
        let cloneData = _.clone(ProductReport);
        let newArr = [];
        _.each(cloneData, async (item) => {
            let resp = await calculation(item, stateOfSupply, companyState);
            newArr.push(resp);
        });

        setProductList(newArr);
    };

    const fetchBatch = async () => {
        let productId = productState.productId;
        let params = {productId}
        const batchNo = batchNumberList.find(item => item.productId == productId);
        if (batchNo) {
            params.batchNo = batchNo.batchNo
        }
        try {
            const response = await axios.get(getBatchForCreditNote(), {
                params: params,
                ...(await getToken()),
            });
            setBatch(response.data.data);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };
    useEffect(() => {
        fetchBatch();
    }, [productState.productId]);

    useEffect(() => {
        fetchStock();
    }, []);

    const onSubmit = () => {
        if (!productState.productId) {
            notification.warning({message: "Please Enter Product"});
            return;
        }
        if (!productState.price) {
            notification.warning({message: "Please Enter Price"});
            return;
        }
        if (!productState.quantity) {
            notification.warning({message: "Please Enter Quantity"});
            return;
        }
        if (productState.discount > 100) {
            notification.warning({
                message: "Please Enter A Valid Discount Percentage",
            });
            return;
        }
        if (!productState.batchId) {
            notification.warning({message: "Please Choose Batch"});
            return;
        }
        // if (productState.BatchQunatity < productState.quantity) {
        //   notification.warning({
        //     message: "Entered Quantity Cannot Be Greater Than Choose Batch",
        //   });
        //   return;
        // }

        if (
            ProductReport.find((x) => {
                return (
                    x.batchNo === productState.batchNo &&
                    x.productId === productState.productId
                );
            })
        ) {
            notification.warning({
                message: "Product Already Exist With Same Batch",
            });
            return;
        }

        const updatedProductReport = [
            ...ProductReport,
            {...productState, serialNo: ProductReport.length + 1},
        ];
        updateProductReport(updatedProductReport);

        setProductState(initState);
    };

    const handleChange1 = (e, fieldName) => {
        const {value} = e.target;
        let obj = {};
        if (fieldName == "productId") {
            const selectedProduct = stock.find(
                (prod) => prod.productId._id === value
            );

            obj = {
                productName: selectedProduct.productId.name,
                pUnit: selectedProduct.productId.unitCode,
                hsn: selectedProduct.productId.hsnCode,
                gst: selectedProduct.productId.gst,
                uom: selectedProduct.productId.unitCode,
                discount: discountCode || ''
            };
            setIsModalOpen(true);
        }
        setProductState({
            ...productState,
            [fieldName]: value,
            stateOfSupply: stateOfSupply,
            ...obj,
        });
    };

    let Cgst = {
        title: "CGST",
        dataIndex: "cgstAmount",
        key: "cgstAmount",
        render: (text, record) => (
            <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
        ),
    };

    let Sgst = {
        title: "SGST",
        dataIndex: "sgstAmount",
        key: "sgstAmount",
        render: (text, record) => (
            <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
        ),
    };

    let Igst = {
        title: "IGST",
        dataIndex: "igstTax",
        key: "igstTax",
        render: (text, record) => (
            <span>
        {text}% (Rs.{record.igstAmount}){" "}
      </span>
        ),
    };

    let NetAmount = {
        title: "Net Amount",
        dataIndex: "netAmount",
        key: "netAmount",
        render: (text) => `Rs. ${text}`,
    };

    let action = {
        title: "Action",
        key: "delete",
        render: (text, record, index) => {
            return (
                <>
                    <a className="empty_btn" onClick={() => handleDelete(index)}>
                        <i className="fa fa-trash-alt"></i>
                    </a>
                </>
            );
        },
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "productName",
            key: "productName",
        },
        {
            title: "Type",
            dataIndex: "reportType",
            key: "reportType",
        },
        {
            title: "HSN",
            dataIndex: "hsn",
            key: "hsn",
        },
        {
            title: "Mfg.",
            dataIndex: "manufacturingDate",
            key: "manufacturingDate",
        },
        {
            title: "Exp.",
            dataIndex: "expiryDate",
            key: "expiryDate",
        },
        {
            title: "Batch",
            dataIndex: "batchNo",
            key: "batchNo",
        },
        {
            title: "Qty.",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "UoM",
            dataIndex: "uom",
            key: "uom",
        },
        {
            title: "Rate",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Total",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (text) => `Rs.${text}`,
        },
        {
            title: "Dis(%)",
            dataIndex: `discount`,
            key: "discount",
            render: (text) => <span>{text}%</span>,
        },
        {
            title: "Dis Amt.",
            dataIndex: `discountAmount`,
            key: "discountAmount",
            render: (text) => <span>Rs.{text}</span>,
        },
        {
            title: "Taxable Amount",
            dataIndex: "taxableAmount",
            key: "taxableAmount",
            render: (text) => `Rs. ${text}`,
        },
    ];

    const handleDelete = (index) => {
        const updatedProductReport = [...ProductReport];
        updatedProductReport.splice(index, 1);
        updateProductReport(updatedProductReport);
    };

    const getColumns = () => {
        if (stateOfSupply == companyState) {
            columns.push(Cgst, Sgst, NetAmount, action);
        } else {
            columns.push(Igst, NetAmount, action);
        }
        return columns;
    };

    useEffect(() => {
        StateChangesRecalculate();
    }, [stateOfSupply, statesAreEqual, k]);


    return (
        <>
            <Card title={"Select Items"} size={"small"}>
                <div className={"item-flex"}>
                    <InputBox title={"Product"} className={"flex2"}>
                        <select
                            style={{height: "45px"}}
                            className="form-control"
                            value={productState.productId}
                            onChange={(e) => handleChange1(e, "productId")}
                            required
                        >
                            <option value="">Select Product</option>
                            {stock.map((stock) => (
                                <option key={stock.productId._id} value={stock.productId._id}>
                                    {stock.productId.name}
                                </option>
                            ))}
                        </select>
                    </InputBox>
                    <InputBox title={"Type"}>
                        <select
                            disabled
                            style={{height: "45px", color: "black"}}
                            className="form-control"
                            value={productState.reportType}
                            onChange={(e) => handleChange1(e, "reportType")}
                            required
                        >
                            <option>Select Tax Type</option>
                            <option>Inclusive</option>
                            <option>Exclusive</option>
                        </select>
                    </InputBox>
                    <InputBox title={"Sale Price"}>
                        <input
                            className={"form-control"}
                            type="number"
                            placeholder="Sale Price"
                            value={productState.price}
                            onChange={(e) => handleChange1(e, "price")}
                        />
                    </InputBox>
                    <InputBox title={"Qty."}>
                        <input
                            className={"form-control"}
                            type="number"
                            value={productState.quantity}
                            placeholder="Quantity"
                            onChange={(e) => handleChange1(e, "quantity")}
                        />
                    </InputBox>
                    <InputBox title={"Dis(%)"}>
                        <input
                            className={"form-control"}
                            type="number"
                            value={productState.discount}
                            placeholder="Discount"
                            onChange={(e) => handleChange1(e, "discount")}
                            max="100"
                        />
                    </InputBox>
                    <InputBox title={"GST(%)"}>
                        <input
                            style={{height: "45px", color: "black"}}
                            disabled
                            className={"form-control"}
                            type="text"
                            value={productState.gst}
                            placeholder="GST"
                            onChange={(e) => handleChange1(e, "gst")}
                        />
                    </InputBox>

                    <InputBox title={"Batch No."}>
                        <input
                            style={{height: "45px", color: "black"}}
                            className={"form-control"}
                            type="text"
                            value={productState.batchNo}
                            placeholder="Batch no."
                            onChange={(e) => handleChange1(e, "batchNo")}
                            disabled
                        />
                    </InputBox>

                    <InputBox className={"flex_none"}>
                        <a
                            className="addBtn fs-5 btn-link"
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit();
                            }}
                        >
                            <i className={`mdi mdi-library-plus menu-icon`}/>
                        </a>
                    </InputBox>
                </div>
            </Card>
            <div key={ProductReport.length} className="mt-4 mb-4">
                <div className="d-flex">
                    <h4 className="m-2">Items List</h4>
                </div>
                <TableMain
                    dataSource={productList}
                    columns={getColumns()}
                    key={productList}
                />
            </div>
            {isModalOpen && (
                <Modal
                    visible={isModalOpen}
                    // closable={false}
                    title={"Choose Batch"}
                    onClose={() => setIsModalOpen(false)}
                >
                    <div>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <td>Batch Number</td>
                                <td>Mfg Date</td>
                                <td>Exp Date</td>
                                <td>Quantity</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                            {Batch.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.batchNo}</td>
                                    <td>{item.manufacturingDateString}</td>
                                    <td>{item.expiryDateString}</td>
                                    <td>{item.quantity}</td>
                                    <td>
                                        <button
                                            className="btn btn-info btn-xs"
                                            onClick={() => handleBatchSelect(item)}
                                        >
                                            Select
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Modal>
            )}
        </>
    );
};
export default ItemListComponent;
